#report-container {
    background: #f5f5f5;
    color: #6e6e6e;
    font-size: 40px;
    flex: 1 1 auto;
    height: auto;
    text-align: center;
}

.layout-dropdown-menu {
    min-width: unset;
    overflow-y: scroll;
    max-height: 40rem;
}

.select-visuals-checkbox {
    color: var(--secondary);
    margin-right: 0.25rem;
}

.dashboard-filters {
    margin-left: 1.2rem;
    margin-right: 1.2rem;
}
