.card-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-form-group {
    flex: 0 1 auto;
    max-width: 30%;
    min-width: 20%;
    padding-bottom: 2rem;
    padding-right: 2rem;
}

.wide-card-form-group {
    flex: 0 1 auto;
    max-width: 50%;
    min-width: 40%;
    padding-bottom: 2rem;
    padding-right: 2rem;
}

.form-action-button {
    margin-bottom: auto;
    min-width: 150px;
}
