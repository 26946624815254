.send-report__modal .modal-content {
    max-width: 512px;
    width: 100%;
}

.send-report__button__submit {
    position: relative;
}

.send-report__button__submit:disabled {
    background-color: #3166c4;
}

.send-report__spinner {
    position: absolute;
}