.project-close-button {
    align-self: flex-end;
    margin: 5px 10px;
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 350px;
}

.card-body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.card-title{
    margin-bottom: 10px;
}

.auto-margin-top {
    margin-top: auto;
}

.card-text-body{
    flex-grow: 1;
    margin-bottom: 15px;
}

.card-top-padding {
    margin-top: 34px;
}
