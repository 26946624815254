.container {
    position: relative;
}

.loading {
    width: 3rem;
    height: 3rem;
    margin: 16px auto;
}

.emptyText {
    background-color: #ffffff;
    border: 1px solid #d9d5db;
    border-radius: 12px;
    color: #6b7280;
    font-size: 18px;
    font-weight: 500;
    line-height: 36px;
    margin-top: 24px;
    padding: 12px;
    text-align: center;
}

.itemsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.item {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    width: 100%;
}

.stageContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    padding-top: 2rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

.selectedStageContainer {
    background: #e2edff;
    box-shadow: -17px -7px 25px 1px rgba(171, 200, 245, 1);
}

.iconContainer {
    align-items: center;
    display: flex;
    height: 76px;
    justify-content: center;
    margin-bottom: 12px;
    margin-top: auto;
}

.arrowButton {
    background-color: #ffffff;
    color: #adb5bd;
    transition: color 0.2s linear;
}

.arrowButton:hover {
    outline: none;
    color: var(--status-color, #86afff);
}

.arrowButton:focus {
    outline: none;
}

.selectedArrow {
    color: var(--status-color, #86afff);
}

.statuses {
    align-items: center;
    display: flex;
    gap: 8px;
}

.summaryStatuses {
    justify-content: center;
    margin-bottom: 2rem;
}

.statusColumn {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.statusIcon {
    color: var(--status-color, #adb5bd);
    height: 20px;
    margin-bottom: 8px;
    width: 20px;
}

.iconCard {
    background: #ffffff;
    border-radius: 50%;
    padding: 16px;
    width: fit-content;

    border: 4px solid #8fbafa;
}

.itemIcon {
    color: var(--status-color, #495057);
    height: 44px;
    width: 44px;
}

.iconErrorCard {
    background-color: #c74443;
}

.iconErrorCard > .itemIcon {
    color: #ffffff;
}

.title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 12px 0px 0px;
    max-width: 94px;
    text-align: center;
}

.badge {
    background: var(--status-color, #adb5bd);
    border-radius: 24px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
    padding: 2px 7px;
}

.emptyBadge {
    background: #adb5bd;
}

.all {
    --status-color: #495057;
}

.processing {
    --status-color: #538af5;
}

.error {
    --status-color: #c74443;
}

.processed {
    --status-color: #76aa34;
}

.stageTitle {
    color: #4f4f4f;
    font-weight: 600;
}

.stageTitleSubtext {
    color: #4f4f4f;
    font-weight: 300;
    margin-top: -1rem;
}

.statusButton {
    border-radius: 0.1875rem;
    padding: 5px;
}

.processingStatusSelected {
    background: rgba(74, 135, 255, 0.39);
    box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.36);
}

.processedStatusSelected {
    background: rgba(177, 223, 123, 0.51);
    box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.36);
}

.errorStatusSelected {
    background: #dccfde;
    box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.36);
}

@media (min-width: 640px) {
    .item {
        width: 50%;
    }

    .item:first-child {
        width: 100%;
    }
}

@media (min-width: 1024px) {
    .itemsContainer {
        justify-content: space-around;
    }
}

@media (min-width: 1280px) {
    .item {
        flex: 1;
        margin-bottom: 0px;
        width: fit-content;
    }

    .item:first-child {
        width: fit-content;
    }

    .itemFooter {
        display: flex;
    }
}
