.auto-margin-top {
    margin-top: auto;
}

.auto-margin-bottom {
    margin-bottom: auto;
}

.data-tools-description {
    color: #495057;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
}

.project-help-button {
    font-family: 'Open Sans';
}

.new-project-form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.project-form-group {
    flex: 1 1 auto;
    max-width: 49%;
    min-width: 40%;
    padding-bottom: 2rem;
}

.data-lookup-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.data-lookup-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: auto;
    max-width: 500px;
}

.data-lookup-arrow {
    color: #56822b;
}

.data-project-text {
    color: #495057;
}

.breadcrumb-z-index {
    z-index: 1;
}

.cluster {
    background-color: lightblue;
    margin: 1rem;
}

.training-records-container {
    border: 1px solid #efeff3;
    box-sizing: border-box;
    border-radius: 12px;
}

.training-records-header {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    border-bottom: 1px solid #d5d7db;
    box-sizing: border-box;
}

.training-records {
    border-bottom: 0.5px solid #d5d7db;
    border-top: 0.5px solid #d5d7db;
}

.file-download-button {
    margin-bottom: 1rem;
    margin-left: auto;
}

.project-history-link {
    margin-right: auto;
    padding-left: 0;
}
