.container {
    background: #ffffff;
    border: 1px solid #e7e7ed;
    border-top-width: 0px;
    padding: 12px 16px 24px;
}

.title {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 12px 0px 16px;
}

.backButton {
    background: transparent;
    border: none;
    color: #3b82f6;
    padding: 0px;
}

.backButton:focus,
.backButton:hover {
    outline: none;
    color: #2563eb;
}

.label {
    font-weight: 600;
}

.text,
.label {
    font-size: 14px;
    line-height: 20px;
}

.check {
    color: #16a34a;
}

.close {
    color: #dc2626;
}
