.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 20px;
}

.list {
    list-style: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 38px;
    margin-left: 0;
    padding: 0;
}

.list li {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    max-height: 60px;
}

.list li span:first-child {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #495057;
}

.list li a,
.list li span:last-child {
    flex: 1;
    display: flex;
    align-items: center;
}

.list li span:last-child {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.veteranStatusBadge {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;

    background: #f5f5f5;
    border-radius: 200px;
}

.badge {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    padding: 8px 16px;
    gap: 10px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
}

.searchRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 0 0.8rem 0;
}

.searchRow span {
    color: var(--gray);
    font-family: 'Open Sans';
    font-size: 14px;
}

.searchRow div {
    width: 25%;
    padding-left: 20px;
}

.searchRow button {
    border-radius: 8px;
}

.statusNormal {
    background: #f5f5f5;
    color: #495057;
}

.statusMedium {
    background: #f7ffef;
    color: #56822b;
}

.serviceNavigation {
    width: 100%;
    background: white;
    margin-top: 24px;
    border-radius: 12px 12px 0 0;
    padding: 0px 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    display: flex;
    gap: 5px;
}

.serviceNavigation button:not(.editButton) {
    background: transparent;
    border: none;
    outline: none;
    padding: 12px 18px;
    font-weight: 400;
    height: 100%;
    transition: color 0.3s ease-out;

    position: relative;
}

.serviceNavigation button:not(.editButton)::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 100%;
    background: transparent;
}

.serviceNavigation button:not(.editButton):hover {
    color: var(--blue);
}

.serviceNavigation button.active::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 100%;
    background: var(--blue);
    border-radius: 8px;
    animation: width 0.3s ease-out;
}

.serviceNavigation button:not(.editButton).active {
    font-weight: 600;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.infoCardsContainer {
    display: flex;
    flex: 2;
    flex-direction: column;
    gap: 16px;
}

.infoCardsContainer > div {
    flex: 1;
}

@keyframes width {
    0% {
        scale: 0;
    }

    100% {
        scale: 1;
    }
}

@media (min-width: 1280px) {
    .infoContainer {
        flex-direction: row;
        gap: 24px;
    }

    .infoCardsContainer {
        gap: 24px;
    }
}
