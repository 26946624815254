.option {
    align-items: center;
    display: flex;
}

.optionColor {
    background: #d5d7db;
    border: 1px solid #d1d5db;
    height: 20px;
    margin-right : 16px;
    width: 30px;
}