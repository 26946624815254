.threshold-input {
    max-width: 6rem;
}

.threshold-form-group {
    align-self: flex-end;
    padding-bottom: 1rem;
}

.data-element-config {
    background: #fff;
    border-radius: 8px;
}

.recipients-input {
    min-width: 20rem;
}

.active-config {
    background: #fff;
    border: 1px solid #efeff3;
    border-radius: 12px;
}

.config-create-button {
    margin-left: auto;
    margin-right: auto;
}

.missing-config-div {
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
