.status-badge.complete {
    background-color: #f7ffef;
    color: #365813;
}

.status-badge.incomplete {
    background-color: rgba(102, 161, 174, 0.16);
    color: #2f80ed;
}

.status-badge.abandoned {
    background-color: #fff2f3;
    color: #9f0100;
}

.status-badge {
    border-radius: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: min-content;
}
