.logo-container {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
}

.apps-icon {
    fill: white;
    align-self: center;
}

.apps-button {
    padding: 0;
    align-self: center;
}

.logo-text {
    margin-bottom: -5px;
}

@media (min-width: 768px) {
    .logo-container {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        gap: 12px;
    }
}

@media (max-width: 768px) {
    .logo-container {
        display: flex;
        flex-direction: column;
    }
}

.apps-popover {
    border-radius: 12px;
}

.apps-popover-header {
    border-radius: 12px 12px 0px 0px;
}

.apps-popover-switch-text {
    margin: unset;
}
