.fieldContainer {
    position: relative;
}

.errorMessage {
    position: absolute;
    top: 100%;
    animation: fadeInTop 0.2s ease-out;
}

@keyframes fadeInTop {
    0% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}