.splash-screen-container {
    min-height: 100vh;
}

.splash-screen-contents {
    margin: auto;
}

.splash-screen-text {
    align-self: center;
}

.reload-button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    padding: 1rem;
    min-width: 200px;
}

.bug-image {
    margin-bottom: 1rem;
}
