.indexed-step-container {
    margin-top: 3.4rem;
    max-width: 5.5rem;
    text-align: center;
}

.indexedStep {
    color: white;
    width: 2rem;
    height: 2rem;
    font-size: 1em;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    background-color: #d5d7db;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.indexedStep.accomplished {
    background-color: #56822b;
}

.indexedStep.active {
    background-color: #3166c4;
}

.progress-bar-padding {
    padding-bottom: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1.5rem;
}
