.top-nav-item-label {
    /* Form Input/Label Default */
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    /* identical to box height */
    text-align: center;

    color: #495057;
}

.top-nav-item-icon {
    fill: #89878f;
    margin-top: auto;
    margin-bottom: auto;
}

.user-icon {
    fill: #3166c4;
}

.top-nav-dropdown-menu {
    max-height: 90vh;
    overflow-y: scroll;
}
