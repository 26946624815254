:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #effcff;
    --toastify-color-success: #f7ffef;
    --toastify-color-warning: #feeee7;
    --toastify-color-error: #fff2f3;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);
    --toastify-icon-color-info: #003490;
    --toastify-icon-color-success: #76aa34;
    --toastify-icon-color-warning: #f57338;
    --toastify-icon-color-error: #9f0100;
    --toastify-toast-width: 400px;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: 'Open Sans';
    --toastify-z-index: 9999;
    --toastify-text-color-light: #757575;
    --toastify-text-color-dark: #fff;
    --toastify-text-color-info: #003490;
    --toastify-text-color-success: #5f892a;
    --toastify-text-color-warning: #db4c0b;
    --toastify-text-color-error: #9f0100;
    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;
    --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
    --toastify-color-progress-dark: #bb86fc;
    --toastify-color-progress-info: #003490;
    --toastify-color-progress-success: #76aa34;
    --toastify-color-progress-warning: #f57338;
    --toastify-color-progress-error: #9f0100;
}

#content-wrapper {
    min-height: 100vh;
}

.Toastify__close-button {
    color: #000000;
}

.Toastify__toast {
    cursor: default;
}

.Toastify__toast-body {
    cursor: text;
}

.large-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 265px;
    height: 50px;
    left: 27px;
    top: 36px;
}

.medium-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 211px;
    height: 40px;
    left: 20px;
    top: 412px;
}

.small-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0.5rem;
    font-weight: 600;
    font-size: 0.8rem;
    border-radius: 8px;
}

.base-text {
    font-family: Montserrat;
}

.nav-item-contents {
    line-height: 24px;
    margin: 0px 5px;
}

.content-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
}

.content-title {
    align-self: flex-end;
    margin-bottom: 0;
}

.content-column-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 2rem;
}

.button-icon-padding {
    margin-right: 0.5rem;
}

.primary-icon-color {
    color: #3166c4;
}

.warning-icon-color {
    color: #f57338;
}

.breadcrumb-chevron {
    fill: #adb5bd;
}

.powerBi-container {
    flex: 1 1 auto;
    height: auto;
}

.flex-column {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.space-between {
    justify-content: space-between;
}

.auto-margin {
    margin: auto;
}

.full-width {
    width: 100%;
}

.button-spinner {
    height: 24px;
    width: 24px;
}

.centered-spinner {
    margin-left: auto;
    margin-right: auto;
    width: 3rem;
    height: 3rem;
    margin-top: 2rem;
    border-width: 0.3em;
    margin-bottom: 2rem;
}

iframe {
    border: none;
    outline: none !important;
}

select option:checked {
    background: #fff -webkit-linear-gradient(bottom, #8c68cd 0%, #8c68cd 100%);
}

.filterFormContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 8px;
}

.filterFormContainer > div {
    display: inline-block;
}

.filterFormContainer .form-label {
    font-size: 14px;
    font-weight: 600;
}

.filterSelect > div {
    border-radius: 8px;
    border-color: #d5d7db;
}

.inputIcon > .input-group-text {
    padding: 4px;
    background-color: #fff;
    border-right: none;
    border-radius: 8px 0 0 8px;
}

.inputIcon > .input-group-text > svg:first-child {
    color: #3166c4;
    height: 20px;
}

.inputIcon > .form-control {
    border-left: none;
    padding-left: 0;
}

.inputIcon-reverse > .input-group-text {
    padding: 4px;
    background-color: #fff;
    border-left: none;
    border-radius: 0 8px 8px 0;
}

.inputIcon-reverse > .input-group-text > svg:last-child {
    color: #3166c4;
    height: 20px;
}

.inputIcon-reverse > .form-control {
    border-right: none;
    padding-right: 0;
    border-radius: 8px 0 0 8px;
}

.filtersAppliedContainer {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: -0.25rem;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #495057;
}

.data-table-filter {
    min-width: 13rem;
}

.clickable {
    cursor: pointer;
}

.project-card {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #d5d7db;
    border-radius: 12px;
}

.project-card-header {
    padding: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project-card-body {
    padding: 0 2rem 2rem 2rem;
    margin-bottom: auto;
}

.project-card-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #d5d7db;
}
.category-pill {
    background-color: #d5d7db;
    border-radius: 2rem;
}

.react-datepicker-wrapper {
    display: flex;
}

.react-datepicker {
    border-radius: 8px;
}

@media (max-width: 768px) {
    .d-md-none-inverted {
        display: none !important;
    }
}

.save-button {
    margin-bottom: auto;
    min-width: 150px;
}

.is-invalid .base-select__control {
    border-color: #e74a3b;
}

.is-invalid .base-select__control:focus-within {
    border-color: #e74a3b;
    box-shadow: 0 0 0 0.2rem rgb(231 74 59 / 25%);
}

.blur-filter {
    -webkit-filter: blur(4px);
    filter: blur(4px);
}

.no-padding {
    padding: 0;
}

.unset-overflow {
    overflow-y: unset !important;
}

.alert-warning-text{
    color: #db4c0b;
}