.container {
    background-color: #fff;
    border: 1px solid #d5d7db;
    border-radius: 12px;
    flex: 1;
    padding: 20px 24px;
}

.title {
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    margin: 0px;
    margin-bottom: 8px;
}

.content > * ~ * {
    margin-top: 20px;
}

.contentRow {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.label {
    color: #495057;
}

.text,
.label,
.veteranBadge,
.statusBadge {
    font-size: 14px;
    line-height: 20px;
}

.veteranBadge,
.statusBadge {
    border-radius: 24px;
    display: flex;
    padding: 8px 16px;
    width: fit-content;
}

.veteranBadge {
    background: #f5f5f5;
}

.statusBadge {
    background: #f7ffef;
}

@media (min-width: 1024px) {
    .container {
        padding: 32px 48px;
    }
}

.header {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
}
