.alerts-dropdown__toast .toast-icon {
    color: #3166c4;
    margin-right: 0.5rem;
}

.alerts-dropdown__toast--seen .toast-header, .alerts-dropdown__toast--seen .toast-body {
    color: #a1a1aa;
}

.alerts-dropdown__toast--seen .toast-icon {
    color: #d1d5db;
}

.alerts-dropdown__toast--danger .toast-icon {
    color: #9f0100;
}

.alerts-dropdown__toast--success .toast-icon {
    color: #76aa34;
}

.alerts-dropdown__toast--warning .toast-icon {
    color: #f57338;
}

.alerts-dropdown__toast--danger.alerts-dropdown__toast--seen .toast-icon,
.alerts-dropdown__toast--success.alerts-dropdown__toast--seen .toast-icon,
.alerts-dropdown__toast--warning.alerts-dropdown__toast--seen .toast-icon {
    opacity: .4;
}
