.expand-icon {
    color: #3166c4;
}

.expanded-clusters {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cluster-record {
    /* Layout/Background */
    background: #f5f5f5;

    /* Layout/Border */
    border: 1px solid #d5d7db;
    border-radius: 12px;

    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 5rem;
    padding-right: 5rem;

    margin: auto;

    width: 500px;
}

.cluster-action-buttons {
    margin: 1rem;
    width: 10rem;
}

.cluster-confidence {
    border-radius: 2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    width: min-content;
}

.cluster-confidence.high {
    background-color: #f7ffef;
    color: #365813;
}

.cluster-confidence.medium {
    background-color: #fff5ef;
    color: #af5f00;
}

.cluster-confidence.low {
    background-color: #fff2f3;
    color: #9f0100;
}

.cluster-confidenc-header {
    font-weight: 700;
    font-family: 'Open Sans';
    color: #000000;
}

.cluster-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}

.confirm-action-active {
    color: #76aa34;
}

.reject-action-active {
    color: #c74443;
}

.cluster-action-inactive {
    color: #89878f;
}

.cluster-progress-bar {
    min-width: 20rem;
}

.table-header-button,
.table-header-button > span {
    display: inline-block;
    border: none;
    background-color: transparent;
    color: #3166c4;
}

.table-header-button:focus {
    outline: none;
}

.cluster-table-empty {
    color: #6b7280;
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    text-transform: capitalize;
}

.table .hidden {
    display: none;
}

@media (min-width: 640px) {
    .footer-label {
        display: flex;
    }

    .table .sm\:table-cell {
        display: table-cell;
    }
}

@media (min-width: 768px) {
    .table .md\:table-cell {
        display: table-cell;
    }
}

@media (min-width: 1024px) {
    .table .lg\:table-cell {
        display: table-cell;
    }
}

@media (min-width: 1280px) {
    .table .xl\:table-cell {
        display: table-cell;
    }
}

@media (min-width: 1536px) {
    .table .\32xl\:table-cell {
        display: table-cell;
    }
}
