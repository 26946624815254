.backButton {
    background: transparent;
    border: none;
    color: #3b82f6;
    padding: 0px;
}

.backButton:focus, .backButton:hover {
    outline: none;
    color: #2563eb;
}

.label {
    font-weight: 600;
}

.text, .label {
    font-size: 14px;
    line-height: 20px;
}