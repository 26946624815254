.container {
    width: 100%;
}

.body {
    display: flex;
    gap: 20px;
    overflow: auto;
    height: 32rem;
}

.card {
    border-radius: 8px;
    border: 1px solid rgba(16, 16, 16, 0.2);
    width: 100%;
    overflow: auto;
    max-height: 30rem;
    width: 18rem;
}

.card > div:not(.cardBody) {
    padding: 6px;
}

.card > div:not(.cardBody) label {
    margin-left: 10px;
    font-weight: 500;
}

.card span {
    background: #0a58ca;
    position: sticky;
    top: 0;
    padding: 4px 2px;
    display: block;
    color: white;
}

.cardBody div {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
}

.cardBody label,
.card label {
    margin: 0;
    cursor: pointer;
}
.cardBody input,
.card input {
    cursor: pointer;
}
