.date-picker-container {
    position: relative;
}

.date-picker-container .date-picker {
    width: 100%;
}

.date-picker-container .clear {
    align-items: center;
    display: flex;
    height: 38px;
    position: absolute;
    right: 8px;
    top: 0px;
}

.date-picker-container .clear > button {
    align-items: center;
    display: flex;
    background: transparent;
    border: none;
    border-radius: 12px;
    height: 24px;
    justify-content: center;
    outline: none;
    padding: 0;
    transition: all .2s;
    width: 24px;
}

.date-picker-container .clear > button:hover {
    background-color: #f3f4f6;
}