.container {
    position: relative;
    margin: 0px 8px;
}

.menu {
    background: #ffffff;
    border: 0.5px solid #d9d5db;
    border-radius: 4px;
    box-shadow: 
        0px 6px 10px rgb(0 0 0 / 14%),
        0px 1px 18px rgb(0 0 0 / 12%),
        0px 3px 5px rgb(0 0 0 / 20%);
    max-height: 50vh;
    max-width: 256px;
    min-width: 125%;
    overflow: auto;
    padding: 4px 0px;
    position: absolute;
    top: calc(100% + 4px);
    right: 0;
    width: max-content;
    z-index: 1000;
}

.item {
    align-items: center;
    background: #ffffff;
    color: #3a3b45;
    display: flex;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 20px;
    padding: 4px 12px;
}

.emptyMessage {
    font-weight: 500;
    width: 100%;
    text-align: center;
}

.draggingItem {
    border: 0.5px solid #d9d5db;
    box-shadow: 
        0 10px 15px -3px rgb(0 0 0 / 0.1),
        0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.item:hover {
    background: #f5f5f5;
}

.button, .buttonIcon {
    background: transparent;
    border: none;
    padding: 0;
}

.button:hover, .button:focus, .buttonIcon:hover, .buttonIcon:focus {
    outline: none;
}

.buttonIcon {
    color: #9ca3af;
}

.buttonIcon:not([disabled]):hover {
    color: #3b82f6;
}

.buttonIcon:disabled {
    opacity: 0.4;
}

.moveContainer {
    align-items: center;
    display: flex;
    margin-left: auto;
}

.draggable {
    display: none;
    margin-left: auto;
}

@media (min-width: 640px) {
    .menu {
        max-width: 356px;
    }

    .moveContainer {
        display: none;
    }

    .draggable {
        display: block;
    }
}