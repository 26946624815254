.active-nav-item-custom a {
    font-weight: 600 !important;
    color: #0a58ca !important;
    background-color: #f7ffef;
}

.expansion-icon {
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
}

.icon-to-right {
    transform: rotate(0deg);
}

.icon-to-down {
    transform: rotate(90deg);
}

@media (min-width: 768px) {
    .sub-nav-item-padding {
        padding-left: 3rem !important;
    }
}

@media (max-width: 768px) {
    .collapse-popover-nav-item {
        top: -80px;
    }
}

.sub-nav-item {
    font-size: 0.8em !important;
    height: 3rem !important;
    width: 100% !important;
}

.collapse-popover {
    min-width: 10rem;
    animation: side-left 0.2s ease-out;
}

@keyframes side-left {
    0% {
        transform: translateX(-15px);
    }

    100% {
        transform: translateX(0px);
    }
}
