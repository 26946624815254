.table-container {
    background: #ffffff;
    border: 1px solid #e7e7ed;
    border-radius: 12px;
}

.table-container-without-top-radius {
    background: #ffffff;
    border: 1px solid #e7e7ed;
    border-radius: 0 0 12px 12px;
}

.footer-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;

    /* or 18px */
    display: none;
    align-items: center;
    text-align: right;
    letter-spacing: 0.16px;

    /* Text/Primary - black */
    color: #000000;

    padding-right: 1rem;
}

.footer-section {
    display: flex;
    flex-direction: row;
}

.data-table-footer {
    padding: 12px 24px;
    width: 100%;
    height: 64px;
    background: #f5f5f5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 0px 0px 12px 12px;

    flex: none;
    order: 9;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
}

.sort-icon {
    margin-left: 0;
    margin-right: 0.5rem;
}

.page-size:focus {
    outline-style: solid;
    outline-color: #86afff;
    border-color: #86afff;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    margin-top: 25%;
    margin-bottom: 25%;
}

.navigable-row {
    cursor: pointer;
}

.table tbody tr.expanded-row {
    border-top-width: 0.9px;
    border-bottom-width: 0px;
}

.table tbody tr.expanded-row:last-child {
    border-bottom-width: 0.9px;
}

.spinner-container {
    text-align: center;
}

.table-spinner {
    width: 3rem;
    height: 3rem;
    border-width: 0.3em;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.table .table__empty-row {
    border-bottom-width: 0px;
}

.table .table__empty-text {
    color: #6b7280;
    font-size: 16px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
    text-transform: capitalize;
}

.table .hidden {
    display: none;
}

.row-selection {
    width: 100%;
}

@media (min-width: 640px) {
    .footer-label {
        display: flex;
    }

    .table .sm\:table-cell {
        display: table-cell;
    }
}

@media (min-width: 768px) {
    .table .md\:table-cell {
        display: table-cell;
    }
}

@media (min-width: 1024px) {
    .table .lg\:table-cell {
        display: table-cell;
    }
}

@media (min-width: 1280px) {
    .table .xl\:table-cell {
        display: table-cell;
    }
}

@media (min-width: 1536px) {
    .table .\32xl\:table-cell {
        display: table-cell;
    }
}

.unset-width {
    width: 0px;
}
