.container {
    height: 100%;
}

.container ul:nth-child(2n + 1) {
    background: rgb(10, 10, 10, 0.1);
}

.title {
    font-weight: 500;
}

.title span {
    color: #0a58ca;
}

.titleCard {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.titleCard span {
    font-weight: 700;
}

.list {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.line {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 16px;
    align-items: flex-start;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding: 6px 0;
}

.line div:not(.cellTitle, .cellOrder, .cellSection) {
    width: 200px;
    display: flex;
    flex-direction: column;
}

.line div span {
    width: 100%;
}

.line div span:nth-child(1) {
    font-weight: 700;
}

.cellOrder {
    font-family: Montserrat;
    font-size: 38px;
    font-weight: 500;
    color: var(--gray);
    width: 30px;
}

.cellOrder span {
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.cellSection {
    flex: 1;
    max-width: 400px;
    display: flex;
    flex-direction: column;
}

.cellSectionWithMinWidth {
    flex: 1;
    max-width: 400px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
}

.lineServiceHeader {
    width: 80%;
}

.cellTitle {
    display: flex;
    gap: 8px;
    font-weight: 700;
}

.cellValue {
    padding-left: 30px;
}
