.batch-runs__container {
    padding: 2rem;
    width: 100%;
}

.batch-runs__container > .content-header {
    margin-bottom: 0.5rem;
}

.batch-runs__filters {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #e7e7ed;
    border-radius: 12px 12px 0px 0px;
    border-bottom-width: 0px;
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
    padding: 12px 24px 16px;
}

.batch-runs__filters .form-label {
    font-size: 0.875rem;
    font-weight: 600;
}

.batch-runs__badge {
    padding: 0px 12px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 20px;
}

.batch-runs__badge.bg-success {
    color: #2e7d32 !important;
    background-color: #bbf7d0 !important;
}

.batch-runs__badge.bg-danger {
    color: #dc2626 !important;
    background-color: #fecaca !important;
}

@media (max-width: 576px) {
    .batch-runs__filters > * {
        width: 100%;
    }
}

.batch-runs__highlight {
    border-radius: 12px;
}

.batch-runs__content {
    animation: fade-in 0.6s linear;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
