.training-result-counters {
    justify-content: flex-end;
}

.training-counter {
    font-family: 'Open Sans';
    padding: 0.5rem;
    border-radius: 2rem;
}

.training-counter.yes {
    background-color: #f7ffef;
    color: #56822b;
}

.training-counter.no {
    background-color: #fff2f3;
    color: #9f0100;
}

.training-counter.unsure {
    background-color: #f7f7f7;
    color: #495057;
}

.show-previous-records {
    align-self: flex-start;
}
