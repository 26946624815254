.container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
}

.spinnerContainer {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
}

.spinner {
    width: 3rem;
    height: 3rem;
}

.actions {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}