.edit-user-panel {
    background: white;
    flex: 1 1 auto;
    z-index: 1;

    display: flex;
    flex-direction: column;

    margin: 0.2rem;
    padding: 1rem;

    max-width: 500px;
}

.edit-panel-header {
    padding-bottom: 1rem;
}

.edit-panel-form {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    padding: 1rem;
}

.edit-panel-footer {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    margin-top: 2rem;
    width: 100%;
    justify-content: flex-end;
}

.team-members-container {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
}

.team-members-main-content {
    flex-direction: column;
    display: flex;
    flex: 3 1 auto;
    padding: 2rem;
}
