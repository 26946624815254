.batch-details {
    padding: 12px 16px;
}

.batch-details__title {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 8px;
}

.batch-details__label {
    font-weight: 600;
}

.batch-details__label,
.batch-details__text {
    font-size: 14px;
    line-height: 20px;
}

.batch-details__tables {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.batch-details__card {
    border: 1px solid #d1d5db;
    border-radius: 12px;
    padding: 12px 16px;
}

.batch-details__card__times {
    display: flex;
    flex-wrap: wrap;
}

.batch-details__card__times > div {
    flex: 1;
}

.batch-details__alert {
    border-radius: 12px !important;
    color: #b91c1c !important;
    margin-bottom: 0px;
    background-color: #fecaca;
}

.batch-details__view-more {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 4px;
}

.batch-details__modal__body > div ~ div {
    margin-top: 16px;
}

@media (min-width: 1024px) {
    .batch-details__tables {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}
