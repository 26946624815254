.container {
    position: relative;
    background-color: #fff;
    border: 1px solid #d5d7db;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
}

.title,
.subTitle {
    font-size: 16px;
    line-height: 24px;
}

.subTitle {
    color: #3166c4;
    font-weight: 600;
}

.title {
    font-weight: 700;
    margin-left: 8px;
}

.viewAllButton {
    align-items: center;
    background: transparent;
    border: none;
    border-radius: 8px;
    color: #3166c4;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    transition: color 0.2s linear;
    white-space: nowrap;
}

.viewAllButton:hover,
.viewAllButton:focus {
    color: #0ea5e9;
    outline: none;
}

.viewAllHeader {
    display: none;
}

.viewAllFooter {
    display: flex;
    justify-content: center;
    margin-top: 16px;
}

.label,
.text {
    font-size: 14px;
    line-height: 20px;
}

.label {
    font-weight: 700;
    margin-bottom: 4px;
}

.number {
    color: #89878f;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 46px;
    margin-right: 12px;
    min-width: 32px;
}

.emptyMessage {
    align-items: center;
    color: #9ca3af;
    display: flex;
    flex: 1;
    font-size: 18px;
    justify-content: center;
    line-height: 28px;
    margin-top: 12px;
}

.content {
    align-items: flex-start;
    display: grid;
    margin-top: 24px;
    gap: 20px;
}

.list > * ~ * {
    margin-top: 12px;
}

.modalContent {
    align-items: flex-start;
    display: grid;
    gap: 20px;
    margin-top: 24px;
}

.serviceContainer > * ~ * {
    margin-top: 24px;
}

.editButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: var(--blue);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 6px;
    color: white;
    font-weight: 400;
    outline: none;
    border: none;
    margin-left: auto;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 18px;
}

@media (min-width: 640px) {
    .modalContent {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 768px) {
    .content {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .viewAllHeader {
        display: block;
    }

    .viewAllFooter {
        display: none;
    }
}

@media (min-width: 1024px) {
    .container {
        padding: 32px 48px;
    }

    .content {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
